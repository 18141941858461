<template>
  <AppTableControl
    @search-clicked="beginSearch"
    @query-debounced="searchByName"
    @export-excel-clicked="downloadReport(1)"
    @export-pdf-clicked="downloadReport(2)"
  >
    <template #filters>
      <div class="d-flex">
        <b-form-group label="Date">
          <DatePicker v-model="date" />
        </b-form-group>

        <b-form-group class="ml-4" label="Status">
          <b-form-select v-model="status" :options="statusOptions">
          </b-form-select>
        </b-form-group>
      </div>
    </template>
  </AppTableControl>
</template>

<script>
import AppTableControl from "@/modules/core/components/shared/filter/AppTableControl";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  name: "StaffDailyAttendanceTableControl",
  components: { AppTableControl, DatePicker },
  data() {
    return {
      date: dayjs().toDate(),
      status: null,
      statusOptions: [
        { value: null, text: "Please select an option" },
        { value: 1, text: "Working" },
        { value: 2, text: "Absent" },
        { value: 3, text: "Leave" },
        { value: 4, text: "Away" },
        { value: 5, text: "Holiday" },
        { value: 5, text: "Offday" },
      ],
    };
  },
  beforeDestroy() {
    console.log("Resetting name base (all) query filter");

    this.$store.dispatch("changeStaffDailyAttendanceNameFilter", {
      name: null,
    });
    this.$store.dispatch("changeStaffDailyAttendanceDateFilter", {
      date: dayjs().format(),
    });
  },
  methods: {
    /**
     * Search using name query.
     *
     * @param nameQuery
     */
    searchByName(nameQuery) {
      console.log(nameQuery);

      this.$store.dispatch("changeStaffDailyAttendanceNameFilter", {
        name: nameQuery,
      });

      this.$store.dispatch("fetchStaffDailyAttendance");
    },

    async downloadReport(type) {
      try {
        this.$bvToast.toast("Downloading Report . . .", {
          variant: "primary",
        });
        const response = await this.$store.dispatch(
          "downloadStaffDailyAttendanceReport",
          {
            type: type,
          }
        );
        console.log(response);
        console.log("Successfully downloaded report of type : ", type);

        this.$bvToast.toast("Successfully downloaded report . . .", {
          variant: "primary",
        });
      } catch (e) {
        console.error(e);

        await this.$bvModal.msgBoxOk("Error", {
          okVariant: "danger",
        });
      }
    },
    /**
     * Search date
     */
    beginSearch() {
      console.log("Begin search", this.date);

      const newDate = dayjs(this.date);

      console.log("newDate", newDate);

      this.$store.dispatch("changeStaffDailyAttendanceDateFilter", {
        date: newDate.format(),
      });

      this.$store.dispatch("changeStaffDailyAttendanceStatusFilter", {
        status: this.status,
      });

      this.$store.dispatch("fetchStaffDailyAttendance");
    },
  },
};
</script>

<style scoped></style>
