<template>
  <div>
    <StaffAttendancePageTableControl class="mb-5" />
    <AppPageCard>
      <template #card-title> {{ $t("EDUATTENDANCE.STAFF.MES1") }}</template>
      <template #card-toolbar>
        <StaffAttendancePageControl></StaffAttendancePageControl>
      </template>
      <b-nav pills>
        <b-nav-item to="daily" exact exact-active-class="active"
          >{{ $t("EDUATTENDANCE.GENERAL.DAILY") }}
        </b-nav-item>
        <b-nav-item to="monthly" exact exact-active-class="active"
          >{{ $t("EDUATTENDANCE.GENERAL.MONTHLY") }}
        </b-nav-item>
        <b-nav-item to="yearly" exact exact-active-class="active"
          >{{ $t("EDUATTENDANCE.GENERAL.YEARLY") }}
        </b-nav-item>
      </b-nav>

      <div class="mt-5">
        <router-view></router-view>
      </div>
    </AppPageCard>
  </div>
</template>

<script>
import AppPageCard from "@/modules/core/components/shared/card/AppPageCard";
import StaffAttendancePageControl from "@/modules/school/components/management/attendance/staff/StaffAttendancePageControl";
import StaffAttendancePageTableControl from "@/modules/school/components/management/attendance/control/StaffAttendancePageTableControl";

export default {
  name: "SchoolStaffAttendancePage",
  components: {
    StaffAttendancePageTableControl,
    StaffAttendancePageControl,
    AppPageCard,
  },
};
</script>

<style scoped></style>
