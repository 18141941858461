<template>
  <EmployeeAttendancePageControl
    @downloadClicked="download"
    @refreshClicked="refresh"
  >
  </EmployeeAttendancePageControl>
</template>

<script>
import EmployeeAttendancePageControl from "@/modules/school/components/shared/management/attendance/controls/EmployeeAttendancePageControl";
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
  name: "StaffAttendancePageControl",
  components: { EmployeeAttendancePageControl },
  methods: {
    /**
     *
     */
    refresh() {
      const type = this.getRouteType();

      switch (type) {
        case "daily":
          this.$store.dispatch("fetchStaffDailyAttendance");
          break;
        case "monthly":
          this.$store.dispatch("fetchSchoolStaffMonthlyAttendance");
          break;
        case "yearly":
          this.$store.dispatch("fetchStaffYearlyAttendanceList");
          break;
        default:
          break;
      }
    },

    /**
     * Download attendance report based on type (montly, daily, etc)
     */
    download(data) {
      const type = this.getRouteType();

      switch (type) {
        case "daily": {
          const todayDate = dayjs().format();
          this.$store.dispatch("downloadStaffDailyAttendanceReport", {
            date: todayDate,
            type: data.format,
          });
          break;
        }
        case "monthly": {
          const todayDate = this.monthlyDate ?? dayjs().format();
          this.$store.dispatch("downloadStaffMonthlyAttendanceReport", {
            date: todayDate,
            type: data.format,
          });
          break;
        }
        default:
          break;
      }
    },

    /**
     * Get route child type.
     *
     * @returns {string}
     */
    getRouteType() {
      const route = this.$route.name;
      console.log("Current route :", route);

      let type = null;
      if (route === "attendanceStaff") {
        console.log("Route is Staff daily attendance.");
        type = "daily";
      } else if (route === "attendanceStaffMonthly") {
        console.log("Route is Staff monthly attendance.");
        type = "monthly";
      } else {
        type = "yearly";
      }

      return type;
    },
  },
  computed: {
    ...mapGetters({
      monthlyDate: "getSchoolStaffMonthlyListDate",
    }),
  },
};
</script>

<style scoped></style>
