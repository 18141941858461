<template>
  <StaffDailyAttendanceTableControl v-if="type === 1" />
  <StaffMonthlyAttendanceTableControl v-else-if="type === 2" />
  <StaffYearlyAttendanceTableControl v-else-if="type === 3" />
</template>

<script>
import StaffDailyAttendanceTableControl from "@/modules/school/components/management/attendance/control/StaffDailyAttendanceTableControl";
import StaffMonthlyAttendanceTableControl from "@/modules/school/components/management/attendance/control/StaffMonthlyAttendanceTableControl";
import StaffYearlyAttendanceTableControl from "@/modules/school/components/management/attendance/control/StaffYearlyAttendanceTableControl";
export default {
  name: "StaffAttendancePageTableControl",
  components: {
    StaffYearlyAttendanceTableControl,
    StaffMonthlyAttendanceTableControl,
    StaffDailyAttendanceTableControl,
  },
  data() {
    return {
      type: null,
    };
  },

  mounted() {
    this.checkRoute(this.$route.name);
  },

  methods: {
    checkRoute(name) {
      switch (name) {
        case "attendanceStaff":
          this.type = 1;
          break;
        case "attendanceStaffMonthly":
          this.type = 2;
          break;
        case "attendanceStaffYearly":
          this.type = 3;
          break;
        default:
          this.type = 4;
          break;
      }
    },
  },

  watch: {
    $route(to, from) {
      console.log(to, from);

      this.checkRoute(to.name);
    },
  },
};
</script>

<style scoped></style>
